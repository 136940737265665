import React from "react";
import {
  ContentHolder,
  BackgroundImage,
  Text,
  TextContainer,
} from "./ContactContentHolderElements";
import Header from "../Header/Header";
import Events from "../Events/Events";
import { getRandomBg } from "../Header/Background";

const ContactContentHolder = (props) => {
  return (
    <ContentHolder id="ContentHolder">
      <BackgroundImage backgroundImage={getRandomBg()} />
      <Header />
      <TextContainer>
        <Text>danny.better@gmail.com</Text>
      </TextContainer>
    </ContentHolder>
  );
};

export default ContactContentHolder;
