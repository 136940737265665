import { useEffect } from "react";

const Press = () => {
  useEffect(() => {
    window.location.href =
      "https://drive.google.com/drive/folders/18aMuXgXVDRzH-o-y2_QWQ50vrOkRApFe?usp=sharing";
  }, []);

  return null;
};

export default Press;
