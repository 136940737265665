import http from "./http-common";

export const PostEvent = (data) => {
  return http.post("/events", data).then((res) => {
    return res;
  });
};

export const GetAllEvents = () => {
  return http.get("/events").then((res) => {
    return res;
  });
};

export const GetEvent = (data) => {
  return http.get(`/events/${data.ID}`).then((res) => {
    return res;
  });
};

export const DeleteEvent = (data) => {
  return http.delete(`/events/${data.ID}`).then((res) => {
    return res;
  });
};

export const UpdateEvent = (data) => {
  return http.put(`/events/${data.ID}`, data).then((res) => {
    return res;
  });
};
