import FirstImage from "../../images/bg/1.webp";
import SecondImage from "../../images/bg/2.webp";
import ThirdImage from "../../images/bg/3.webp";
import FourthImage from "../../images/bg/4.webp";

export const getRandomBg = () => {
  const numbers = [];
  const min = 1,
    max = 4;

  const rand = Math.round(min + Math.random() * (max - min)) - 1;
  numbers.push(FirstImage, SecondImage, ThirdImage, FourthImage);

  return numbers[rand];
};
