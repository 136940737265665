import React from "react";
import { HeaderContainer } from "./HeaderElements";
import BetterLogoImg from "../../images/DB_logo_png.webp";
import { useNavigate } from "react-router-dom";

const HeaderNoLinksNoLogo = (props) => {
  const navigate = useNavigate();

  return <HeaderContainer></HeaderContainer>;
};

export default HeaderNoLinksNoLogo;
