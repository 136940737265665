import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Dates from "./pages/Home/Dates";
import Admin from "./pages/Admin/Admin";
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import { Private } from "./components/Auth/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import Contact from "./pages/Home/Contact";
import Press from "./pages/Press/Press";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dates" element={<Dates />} />
        <Route path="/contact" element={<Contact />} />
        <Route element={<Private />}>
          <Route path="/signup" exact element={<Signup />} />
        </Route>
        <Route element={<Private />}>
          <Route path="/admin" exact element={<Admin />} />
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />
        <Route path="/press" exact element={<Press />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
