import React from "react";
import { ContentHolder, BackgroundImage } from "./AdminContentHolderElements";
import Header from "../Header/Header";
import FirstImage from "../../images/bg/1.webp";
import SecondImage from "../../images/bg/2.webp";
import ThirdImage from "../../images/bg/3.webp";
import FourthImage from "../../images/bg/4.webp";

import AdminTable from "./AdminTable";

const HomeContentHolder = (props) => {
  const numbers = [];

  const getRandomBg = () => {
    const min = 1,
      max = 4;

    const rand = Math.round(min + Math.random() * (max - min)) - 1;
    numbers.push(FirstImage, SecondImage, ThirdImage, FourthImage);

    return numbers[rand];
  };

  return (
    <ContentHolder id="ContentHolder">
      <BackgroundImage backgroundImage={getRandomBg()} />
      <Header />
      <AdminTable />
    </ContentHolder>
  );
};

export default HomeContentHolder;
