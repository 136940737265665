import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { UpdateEvent } from "../../services/eventService";
import { AiFillEdit } from "react-icons/ai";

const EditEventModal = ({
  ID,
  date: initialDate,
  place: initialPlace,
  link: initialLink,
  onEventUpdated,
}) => {
  const [showModal, setShowModal] = useState(false);
  const place = useRef(initialPlace);
  const date = useRef(new Date(initialDate).toISOString().slice(0, 10));
  const link = useRef(initialLink);

  const newEventString = "Esemény szerkesztése";
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const submitForm = async () => {
    try {
      await UpdateEvent({
        ID,
        date: date.current.value,
        place: place.current.value,
        link: link.current.value,
      });

      handleClose();
      onEventUpdated(); // Update the event list
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AiFillEdit style={{ cursor: "pointer" }} onClick={handleShow} />
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{newEventString}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label>Dátum</Form.Label>
              <Form.Control
                type="date"
                name="date"
                ref={date}
                defaultValue={date.current}
                placeholder="Add meg a dátumot"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPlace">
              <Form.Label>Helyszín</Form.Label>
              <Form.Control
                type="input"
                name="place"
                defaultValue={place.current}
                ref={place}
                placeholder="Add meg a helyszínt"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLink">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="input"
                name="link"
                ref={link}
                defaultValue={link.current}
                placeholder="Add meg az esemény linkjét"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Bezárás
          </Button>
          <Button variant="primary" onClick={submitForm} type="submit">
            Mentés
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditEventModal;
