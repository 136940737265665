import React from "react";
import { Container } from "react-bootstrap";
import SignupContent from "../../components/Auth/SignupContent";
import { getRandomBg } from "../../components/Header/Background";
import {
  AuthContainer,
  BackgroundImage,
  ContentHolder,
} from "../../components/Auth/AuthElements";
import { AuthProvider } from "../../contexts/AuthContext";
import HeaderNoLinks from "../../components/Header/HeaderNoLinks";
import ForgotPassword from "../../components/Auth/ForgotPasswordContent";

export default function ForgetPassword() {
  return (
    <ContentHolder>
      <BackgroundImage backgroundImage={getRandomBg()} />
      <HeaderNoLinks />
      <AuthContainer>
        <Container className="d-flex align-items-center justify-content-center">
          <div className="w-100" style={{ maxWidth: "400px" }}>
            <AuthProvider>
              <ForgotPassword />
            </AuthProvider>
          </div>
        </Container>
      </AuthContainer>
    </ContentHolder>
  );
}
