import styled from "styled-components";

export const ContentHolder = styled.div.attrs((props) => ({
  className: "ContentHolder",
}))`
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  min-height: 100vh;
`;

export const ContentHolderCenter = styled.div.attrs((props) => ({
  className: "ContentHolder",
}))`
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackgroundImage = styled.div.attrs((props) => ({
  className: "BackgroundImage",
}))`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000000;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  z-index: 0;
  opacity: 0.2;

  @media screen and (max-width: 480px) {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }
`;

export const TextContainer = styled.div.attrs((props) => ({
  className: "TextContainer",
}))`
  display: flex;
  margin: auto;
  justify-content: center;
  max-width: 90%;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 480px) {
  }
`;

export const Text = styled.p.attrs((props) => ({
  className: "Text",
}))`
  font-family: "Exo";
  text-align: center;
  font-size: 1.5rem;
  z-index: 1;
  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;
