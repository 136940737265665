import { DeleteEvent } from "../../services/eventService";

export const autoDeleteEvents = async (events, today, setEvents) => {
  const updatedEvents = [];

  for (const event of events) {
    let eventDate = new Date(event.date);
    if (
      (eventDate.getMonth() < new Date(today).getMonth() &&
        eventDate.getFullYear() <= new Date(today).getFullYear()) ||
      eventDate.getFullYear() < new Date(today).getFullYear()
    ) {
      try {
        await DeleteEvent({ ID: event._id });
      } catch (error) {
        console.log(error);
      }
    } else {
      updatedEvents.push(event);
    }
  }

  setEvents(updatedEvents);
};
