import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // Cleanup function to cancel any pending asynchronous tasks
      // when the component is unmounted
      setLoading(false); // Ensure loading state is reset
    };
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/admin");
    } catch {
      setError("Sikertelen bejelentkezés");
    } finally {
      setLoading(false); // Make sure to set loading state to false
    }
  }

  return (
    <>
      <Card bg="dark" text="white">
        <Card.Body>
          <h2 className="text-center mb-4">Bejelentkezés</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Jelszó</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100 mt-4" type="submit">
              Bejelentkezés
            </Button>
          </Form>
          <div className="w-100 text-center mt-3 text-white">
            <Link to="/forgot-password">Elfelejtetted a jelszavad?</Link>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
