import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBUoPd4ToCycODG9_tE4mPg00c9bhffnBU",
  authDomain: "dannybetter-73902.firebaseapp.com",
  projectId: "dannybetter-73902",
  storageBucket: "dannybetter-73902.appspot.com",
  messagingSenderId: "189896860280",
  appId: "1:189896860280:web:e8441e873cf78adb97438d",
  measurementId: "G-6RCT2X1RGG",
});

export const auth = app.auth();
export default app;
