import React from "react";
import {
  ContentHolderCenter,
  BackgroundImage,
} from "./HomeContentHolderElements";
import Header from "../Header/Header";
import Events from "../Events/Events";
import { getRandomBg } from "../Header/Background";
import HeaderNoLinksNoLogo from "../Header/HeaderNoLinksNoLogo";

const DateContentHolder = (props) => {
  return (
    <ContentHolderCenter id="ContentHolder">
      <BackgroundImage backgroundImage={getRandomBg()} />
      <HeaderNoLinksNoLogo />
      <Events notext={true} />
    </ContentHolderCenter>
  );
};

export default DateContentHolder;
