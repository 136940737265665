import styled from "styled-components";

export const ContentHolder = styled.div.attrs((props) => ({
  className: "ContentHolder",
}))`
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  min-height: 100vh;
`;

export const ContentHolderCenter = styled.div.attrs((props) => ({
  className: "ContentHolder",
}))`
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackgroundImage = styled.div.attrs((props) => ({
  className: "BackgroundImage",
}))`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000000;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  z-index: 0;
  opacity: 0.2;

  @media screen and (max-width: 480px) {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }
`;
