import styled from "styled-components";
import GradientImage from "../../images/gradient.webp";

export const EventsList = styled.div.attrs((props) => ({
  className: "EventsList",
}))`
  width: fit-content;
  width: fit-content;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    max-width: 90%;
    width: fit-content;
  }
  @media screen and (max-width: 480px) {
    margin-top: 1rem;
  }
`;

export const MonthText = styled.div.attrs((props) => ({
  className: "Month",
}))`
  font-family: "Exo";
  font-weight: 500;
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  margin-left: -1rem;
  height: 2rem;
  z-index: 2;

  @media screen and (max-width: 480px) {
    margin-top: 1rem;
    margin-bottom: 0;
    margin-left: -0.5rem;
    font-size: 1.4rem;
  }
`;

export const YearText = styled.div.attrs((props) => ({
  className: "Year",
}))`
  font-family: "Exo";
  font-weight: 700;
  font-size: 2rem;
  margin-top: 3rem;
  margin-bottom: -1rem;
  height: 2rem;
  z-index: 2;
  margin-left: -1rem;

  @media screen and (max-width: 480px) {
    margin-top: 1rem;
    font-size: 1.75rem;
    margin-left: -0.5rem;
  }
`;

export const DateText = styled.div.attrs((props) => ({
  className: "Date",
}))`
  margin-right: 0.65rem;
  font-family: "Bebas Neue";
  font-weight: 700;
  font-size: 1.2rem;
  height: 1.4rem;
  align-self: flex-end;
  z-index: 2;

  background-size: 100% 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-attachment: fixed;
  background-image: url("${(props) => GradientImage}");

  @media screen and (max-width: 480px) {
    height: 1rem;
    font-size: 0.9rem;
    margin-right: 0.5rem;
  }
`;

export const PlaceText = styled.div.attrs((props) => ({
  className: "Place",
}))`
  height: 1.9rem;
  font-family: "Exo";
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;

  background-size: 100% 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-attachment: fixed;
  background-image: url("${(props) => GradientImage}");

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    height: 1.2rem;
  }

  &:hover {
    -webkit-text-fill-color: #0d6efd;

    @media screen and (max-width: 480px) {
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const Event = styled.div.attrs((props) => ({
  className: "Event",
}))`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  width: fit-content;
  cursor: pointer;
`;

export const TextContainer = styled.div.attrs((props) => ({
  className: "TextContainer",
}))`
  display: flex;
  margin: auto;
  justify-content: center;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 480px) {
  }
`;

export const Text = styled.p.attrs((props) => ({
  className: "Text",
}))`
  font-family: "Exo";
  text-align: center;
  font-size: 1rem;

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
  }
`;
