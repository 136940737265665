import React from "react";
import { ContentHolder, BackgroundImage } from "./HomeContentHolderElements";
import Header from "../../components/Header/Header";
import Events from "../../components/Events/Events";
import { getRandomBg } from "../Header/Background";

const HomeContentHolder = (props) => {
  return (
    <ContentHolder id="ContentHolder">
      <BackgroundImage backgroundImage={getRandomBg()} />
      <Header />
      <Events />
    </ContentHolder>
  );
};

export default HomeContentHolder;
