import styled from "styled-components";

export const ContentHolder = styled.div.attrs((props) => ({
  className: "ContentHolder",
}))`
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const BackgroundImage = styled.div.attrs((props) => ({
  className: "BackgroundImage",
}))`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000000;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  z-index: 0;
  opacity: 0.2;
`;

export const TableContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  z-index: 1;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  z-index: 1;

  @media screen and (max-width: 480px) {
  }
`;
