import React from "react";
import {
  HeaderContainer,
  BetterLogo,
  LinksContentHolder,
  Link,
  LinkLine,
} from "./HeaderElements";
import BetterLogoImg from "../../images/DB_logo_png.webp";
import { useNavigate } from "react-router-dom";

const HeaderNoLinks = (props) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <BetterLogo
        src={BetterLogoImg}
        alt="phoneLogo"
        onClick={() => {
          navigate(`/`);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
    </HeaderContainer>
  );
};

export default HeaderNoLinks;
