import styled from "styled-components";

export const HeaderContainer = styled.div.attrs((props) => ({
  className: "HeaderContainer",
}))`
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media screen and (max-width: 480px) {
    margin-bottom: 1rem;
  }
`;

export const BetterLogo = styled.img.attrs((props) => ({
  className: "BetterLogo",
}))`
  margin: 5rem auto 2rem auto;
  width: 30vw;
  max-width: 300px;
  min-width: 200px;
  z-index: 2;
  cursor: pointer;
`;

export const LinksContentHolder = styled.div.attrs((props) => ({
  className: "LinksContentHolder",
}))`
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;

  @media screen and (max-width: 1024px) {
    width: 45%;
  }

  @media screen and (max-width: 980px) {
    width: 90%;
  }
  @media screen and (max-width: 480px) {
    width: 95%;
    flex-direction: column;
  }
`;

export const Link = styled.a.attrs((props) => ({
  className: "Link",
}))`
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  text-decoration: none;
  z-index: 2;
  font-family: "Exo";
  font-size: 1.2rem;
  font-weight: 100;
`;

export const LinkLine = styled.div.attrs((props) => ({
  className: "LinkLine",
}))`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width};
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 480px) {
    width: fit-content;
    margin-bottom: 0.5rem;
  }
`;
