import HomeContentHolder from "../../components/HomeContentHolder/HomeContentHolder";

const Home = () => {
  
    return (
      <HomeContentHolder />
    );
  };
  
  export default Home;
  