import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { PostEvent } from "../../services/eventService";

const AddEventModal = ({ onEventAdded }) => {
  let today = new Date().toISOString().slice(0, 10);

  const [showModal, setShowModal] = useState(false);
  const place = useRef();
  const date = useRef(today);
  const link = useRef();

  const newEventString = "Új esemény hozzáadása";
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const submitForm = async () => {
    try {
      await PostEvent({
        date: date.current.value,
        place: place.current.value,
        link: link.current.value,
      });

      onEventAdded();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        style={{ marginBottom: "2rem" }}
        variant="dark"
        onClick={handleShow}
      >
        {newEventString}
      </Button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{newEventString}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label>Dátum</Form.Label>
              <Form.Control
                type="date"
                ref={date}
                name="date"
                defaultValue={today}
                placeholder="Add meg a dátumot"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPlace">
              <Form.Label>Helyszín</Form.Label>
              <Form.Control
                type="input"
                name="place"
                ref={place}
                placeholder="Add meg a helyszínt"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLink">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="input"
                name="link"
                ref={link}
                placeholder="Add meg az esemény linkjét"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Bezárás
          </Button>
          <Button variant="primary" onClick={submitForm} type="submit">
            Mentés
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEventModal;
