import React from "react";
import {
  HeaderContainer,
  BetterLogo,
  LinksContentHolder,
  Link,
  LinkLine,
} from "./HeaderElements";
import BetterLogoImg from "../../images/DB_logo_png.webp";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <BetterLogo
        src={BetterLogoImg}
        alt="phoneLogo"
        onClick={() => {
          navigate(`/`);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      <LinksContentHolder>
        <LinkLine width="60%">
          <Link href="https://www.facebook.com/dannybetter">facebook</Link>
          <Link href="https://www.instagram.com/dannybetter/">instagram</Link>
          <Link href="https://www.youtube.com/c/DANNYBETTERofficial">
            youtube
          </Link>
        </LinkLine>
        <LinkLine width="40%">
          <Link href="/contact">kapcsolat</Link>
          <Link href="https://drive.google.com/drive/folders/18aMuXgXVDRzH-o-y2_QWQ50vrOkRApFe?usp=sharing">
            press
          </Link>
        </LinkLine>
      </LinksContentHolder>
    </HeaderContainer>
  );
};

export default Header;
