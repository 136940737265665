import React, { useEffect, useState, useRef } from "react";
import {
  EventsList,
  Event,
  DateText,
  PlaceText,
  MonthText,
  Text,
  TextContainer,
  YearText,
} from "./EventsElements";
import { GetAllEvents } from "../../services/eventService";
import { autoDeleteEvents } from "../AdminContentHolder/AutoDeleteEvents";
import { getMonthName } from "../months";

const Events = (props) => {
  const [events, setEvents] = useState([]);
  const [eventsSorted, setEventsSorted] = useState([]);
  const monthForList = useRef(0);
  const thisYear = new Date().getFullYear();
  const yearForList = useRef(thisYear);
  const [yearTextBool, setYearTextBool] = useState(true);

  const getYearForList = (year) => {
    if (year > yearForList.current) {
      yearForList.current = year;
      return (
        <Event key={`year-${year}`}>
          <YearText>{year}</YearText>
        </Event>
      );
    }
  };

  const getMonthNameForList = (month, year) => {
    if (
      (year === thisYear && month > monthForList.current) ||
      year > thisYear
    ) {
      monthForList.current = month;
      return (
        <Event key={`month-${year}-${month}`}>
          <MonthText>{getMonthName(month)}</MonthText>
        </Event>
      );
    }
  };

  const getEvents = () => {
    GetAllEvents().then((res) => {
      setEvents(res.data);
    });
  };

  const sortDates = () => {
    const sortedEvents = [...events]; // Clone the array before sorting
    sortedEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
    autoDeleteEvents(sortedEvents, new Date()); // Call autoDeleteEvents with the sorted events array
    setEventsSorted(sortedEvents);
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    sortDates();
  }, [events]);

  return (
    <>
      <EventsList>
        {eventsSorted.map((item) => {
          let date = new Date(item.date);
          let newDate;
          if (date.getMonth() < 9) {
            if (date.getDate() < 10) {
              newDate =
                "0" + (date.getMonth() + 1) + "/" + "0" + date.getDate();
            } else {
              newDate = "0" + (date.getMonth() + 1) + "/" + date.getDate();
            }
          } else {
            if (date.getDate() < 10) {
              newDate = date.getMonth() + 1 + "/" + "0" + date.getDate();
            } else {
              newDate = date.getMonth() + 1 + "/" + date.getDate();
            }
          }

          return (
            <React.Fragment key={item._id}>
              {getYearForList(date.getFullYear())}
              {getMonthNameForList(date.getMonth(), date.getFullYear())}
              <Event
                onClick={() => {
                  if (item.link !== undefined && item.link !== "")
                    window.open(item.link, "_blank");
                }}
              >
                <DateText>{newDate}</DateText>
                <PlaceText>{item.place}</PlaceText>
              </Event>
            </React.Fragment>
          );
        })}
      </EventsList>
      {props.notext ? (
        ""
      ) : (
        <TextContainer>
          <Text>Az esemény megtekintéséhez kattints a helyszínre.</Text>
        </TextContainer>
      )}
    </>
  );
};

export default Events;
