import React, { useEffect, useState } from "react";
import { IconContainer, TableContainer } from "./AdminContentHolderElements";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { DeleteEvent, GetAllEvents } from "../../services/eventService";
import { AiFillDelete } from "react-icons/ai";
import AddEventModal from "./AddEventModal";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router";
import EditEventModal from "./EditEventModal";

const AdminTable = (props) => {
  const [events, setEvents] = useState([]);
  const [eventsSorted, setEventsSorted] = useState([]);
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const navigate = useNavigate();

  const monthNames = [
    "január",
    "február",
    "március",
    "április",
    "május",
    "június",
    "július",
    "augusztus",
    "szeptember",
    "október",
    "november",
    "december",
  ];

  const getEvents = () => {
    GetAllEvents().then((res) => {
      setEvents(res.data);
    });
  };

  const sortDates = () => {
    const sortedEvents = [...events]; // Clone the array before sorting
    sortedEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
    setEventsSorted(sortedEvents);
  };

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Sikertelen kijelentkezés");
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    sortDates();
  }, [events]);

  let counter = 0;
  return (
    <>
      <TableContainer>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className=" text-center mb-5">
          <Button variant="link" onClick={handleLogout}>
            Kijelentkezés
          </Button>
        </div>
        <Table responsive striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Dátum</th>
              <th>Helyszín</th>
              <th>Műveletek</th>
            </tr>
          </thead>
          <tbody>
            {eventsSorted.map((item, index) => {
              counter++;
              let date = new Date(item.date);
              let newDate;

              if (date.getDate() < 10) {
                newDate =
                  date.getFullYear() +
                  ". " +
                  monthNames[date.getMonth()] +
                  " 0" +
                  date.getDate() +
                  ".";
              } else {
                newDate =
                  date.getFullYear() +
                  ". " +
                  monthNames[date.getMonth()] +
                  " " +
                  date.getDate() +
                  ".";
              }

              return (
                <tr key={item._id}>
                  <td>{counter}</td>
                  <td>{newDate}</td>
                  <td>{item.place}</td>
                  <td>
                    <IconContainer>
                      <EditEventModal
                        ID={item._id}
                        date={new Date(item.date)}
                        place={item.place}
                        link={item.link}
                        onEventUpdated={getEvents}
                      />
                      <AiFillDelete
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          try {
                            await DeleteEvent({ ID: item._id });
                            const updatedEvents = events.filter(
                              (event) => event._id !== item._id
                            );
                            setEvents(updatedEvents);
                          } catch (error) {
                            // Handle error
                          }
                        }}
                      />
                    </IconContainer>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <AddEventModal onEventAdded={getEvents} />
      </TableContainer>
    </>
  );
};

export default AdminTable;
